var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"12","xl":"9","lg":"8"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"refFormObserver"},[_c('b-row',[_c('b-col',{attrs:{"lg":"5"}},[_c('h3',[_vm._v("Contacto")])]),_c('b-col',{attrs:{"lg":"7"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',{attrs:{"label":"Ciudad/Localidad *"}},[_c('validation-provider',{attrs:{"name":"Ciudad/Localidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null},model:{value:(_vm.formData.region),callback:function ($$v) {_vm.$set(_vm.formData, "region", $$v)},expression:"formData.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Zona/Barrio *"}},[_c('validation-provider',{attrs:{"name":"Zona/Barrio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null},model:{value:(_vm.formData.address_zone),callback:function ($$v) {_vm.$set(_vm.formData, "address_zone", $$v)},expression:"formData.address_zone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Dirección *"}},[_c('validation-provider',{attrs:{"name":"Dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Telefono/Celular de Referencia *"}},[_c('validation-provider',{attrs:{"name":"Telefono/Celular de Referencia","rules":"required|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"type":"number"},model:{value:(_vm.formData.reference_phone_number),callback:function ($$v) {_vm.$set(_vm.formData, "reference_phone_number", $$v)},expression:"formData.reference_phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"3","lg":"4"}},[_c('b-card',[_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v("Guardar")]),_c('b-button',{attrs:{"variant":"outline-secondary","block":""},on:{"click":_vm.handleCancel}},[_vm._v("Cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }